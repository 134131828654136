import { Stack, Typography, darken } from "@mui/material"
import { theme } from "../../../theme"
import { DividerInCountScoreboard } from "./DividerInCountScoreboard"

type Props = {
    countNumber: string,
    background: string,
    textColor?: string
}

export function CountScoreboard(props: Props) {
    const { countNumber, background } = props;
    const textColor = props.textColor == null ? theme.palette.common.white : props.textColor;
    let defaultStyle: any;

    function changeBackground(e: any) {
        defaultStyle = e.target.style.background;
        if (background) {
            e.target.style.background = darken(`${background}`, 0.75);
        }
    }

    function resetBackground(e: any) {
        e.target.style.background = defaultStyle;
    }

    return (
        <Stack
            bgcolor={background}
            color={textColor}
            height={54} width={54}
            borderRadius='50%'
            alignItems='center' 
            justifyContent='center'
            onMouseOver={changeBackground}
            onMouseOut={resetBackground}
        >
            <Typography mt={0.3} variant='body_title' fontSize={13} lineHeight={1} textAlign='center' >{countNumber}</Typography>
            <DividerInCountScoreboard flexItem color={textColor} />
            <Typography mt={0.3} variant='body_title' fontSize={13} lineHeight={1.2} textAlign='center' >XIII</Typography>
        </Stack>
    )
}