import { Container, Link, Stack, Typography, useTheme } from "@mui/material"

type Props = {
    isGreen?: boolean
}

export function Footer(props: Props) {
    const { isGreen } = props
    const theme = useTheme()
    const backgroundColor = isGreen ? theme.palette.success.main : theme.palette.background.default

    return (
        <Stack bgcolor={backgroundColor} height={413} justifyContent='center' component='footer'>
            <Container>
                <Link underline="none" href="https://www.instagram.com/aurelie.minder/" target='_blank' >
                    <Typography
                        color={theme.palette.common.white}
                        fontSize={40}
                        lineHeight={1.2}
                        fontWeight={300}
                        fontFamily='EksellDisplayLarge'
                        textAlign='center'
                    >
                        Follow me on<br />instagram
                    </Typography>
                </Link>
            </Container>
            <Container>
                <Typography
                    color={theme.palette.common.white}
                    textAlign='center'
                    mt='5em'
                >
                    &copy; Bureau AM, 2024
                </Typography>
            </Container>
        </Stack>
    )
}