import { Route, Routes, useLocation } from "react-router-dom";
import { InfoPage } from "../../pages/InfoPage/InfoPage";
import { SelectionPage } from "../../pages/SelectionPage/SelectionPage";
import { SliderPage } from "../../pages/SliderPage";
import { StartPage } from "../../pages/TitlePage";
import { AnimatePresence } from 'framer-motion'
import { Urls } from "../urls/urls";
import { useProjects } from "../../context/ProjectsContext";

export function AnimatedRoutes() {
    const location = useLocation()
    const { projects, loading, error } = useProjects();

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/selection" element={<SelectionPage />} />
                { projects.map((project, index) => (
                    <Route path={Urls.nav(project.name)} element={<SliderPage initialSlide={index} />} key={ project.id } />
                )) }
                <Route path="/info" element={<InfoPage />} />
                <Route path="/*" element={<StartPage />} />

            </Routes>
        </AnimatePresence>
    )
}