import { alpha, Button, styled } from "@mui/material";
import { DisplayEnum } from "../enums/display.enum";

export const CloseButton = styled(Button, { name: 'CloseButton' })<{ background: string, scroll_correction?: number, top?: number }>(({ background, scroll_correction=21, top=60 }) => ({
	background: `${background}`,
	borderRadius: '50%',
	width: '65px',
	height: '65px',
	position: 'absolute',
	top: top,
	right: 47 - scroll_correction,
	'&::before, ::after': {
		position: 'absolute',
		content: "''",
		width: '39px',
		height: '3px',
		background: `${background === '#fff'? '#3F3E45' : '#fff'}`,
		transform: 'rotate(45deg)'
	},
	'&::after': {
		transform: 'rotate(-45deg)'
	},
	':hover': {
        background: alpha(`${background}`, 0.5),
    },
	[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
		position: 'fixed',
		top: '88%',
		left: '50%',
		transform: 'translateX(-50%)',
		background: '#3F3E45',
		'&::before, ::after': {
			background: '#fff',
		},	
	},
}))