import { Stack, useMediaQuery, useTheme } from "@mui/material"
import { StyledImgBox } from "../../../shared/components"
import { DisplayEnum } from "../../../shared/enums/display.enum"

import logo_1 from '../../../assets/images/infoPage/logos/logo_1.png'
import logo_2 from '../../../assets/images/infoPage/logos/logo_2.png'
import logo_3 from '../../../assets/images/infoPage/logos/logo_3.png'
import logo_4 from '../../../assets/images/infoPage/logos/logo_4.png'
import logo_5 from '../../../assets/images/infoPage/logos/logo_5.png'
import logo_6 from '../../../assets/images/infoPage/logos/logo_6.png'
import logo_7 from '../../../assets/images/infoPage/logos/logo_7.png'
import logo_8 from '../../../assets/images/infoPage/logos/logo_8.png'

import logo_mobile_3 from '../../../assets/images/infoPage/logos/logo-mobile_3.png'
import logo_mobile_4 from '../../../assets/images/infoPage/logos/logo-mobile_4.png'
import logo_mobile_5 from '../../../assets/images/infoPage/logos/logo-mobile_5.png'
import logo_mobile_7 from '../../../assets/images/infoPage/logos/logo-mobile_7.png'
import logo_mobile_8 from '../../../assets/images/infoPage/logos/logo-mobile_8.png'
import { Fragment } from "react"


export function LogosGreed() {
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
    return (
        <Fragment >
            <Stack
                flexDirection='row'
                flexWrap='wrap'
                maxWidth={mobile ? '100%' : 950}
                justifyContent={mobile ? 'space-around' : 'center'}
                alignItems='center'
                columnGap={mobile ? 3 : 7}
                rowGap={mobile ? 2 : 2}
                mb={2}
            >
                <StyledImgBox src={logo_1} aspectratio='42.92%' width='45%' />
                <StyledImgBox src={logo_2} aspectratio='50.11%' width='45%' />
                <StyledImgBox src={mobile ? logo_mobile_3 : logo_3} aspectratio='24.373%' width='45%' />
                <StyledImgBox src={mobile ? logo_mobile_4 : logo_4} aspectratio='98%' width='25%' />
                <StyledImgBox src={mobile ? logo_mobile_5 : logo_5} aspectratio='13.11%' width='45%' />
                <StyledImgBox src={logo_6} aspectratio='46.99%' width='45%' />
                <StyledImgBox src={mobile ? logo_mobile_7 : logo_7} aspectratio='75%' width='50%' />
                <StyledImgBox src={mobile ? logo_mobile_8 : logo_8} aspectratio='60%' width='25%' />
            </Stack>
        </Fragment>
    )
}



