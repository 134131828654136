import { Stack, styled } from "@mui/material";
import { DisplayEnum } from "../../../shared/enums/display.enum";

export const StyledStack = styled(Stack, { name: 'StyledStack' })(({
	flexDirection: 'row',
	gap: 12,
	[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
		position: 'absolute',
		bottom: 39,
		left: '50%',
		transform: 'translateX(-50%)',
		zIndex: 2
	},

}))