import { useMediaQuery, useTheme } from "@mui/material"
import { DisplayEnum } from "../../enums/display.enum"
import { StyledImgBox } from "../StyledImgBox"
import { TPoster } from "../../typing"

type Props = {
    poster: TPoster
}
export function ProductPhoto(props: Props){
    const { poster } = props
    const theme = useTheme()
    const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
    const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
    return (
        <StyledImgBox src={poster.path} aspectratio='143.63%' width={mobile? '100%' : '48%' } />
    )
}