import { Accordion, AccordionDetails, styled, Typography, useTheme } from "@mui/material";
import BaseAccordionSummary, { AccordionSummaryProps, } from '@mui/material/AccordionSummary';
import { Box } from "@mui/system";


const StyledAccordion = styled(Accordion)({
    boxShadow: 'none',
    backgroundColor: 'transparent',
    ':before': {
        display: 'none',
    },
    '.MuiAccordionDetails-root': {
        padding: '15px 0',
        margin: 0,
    },
    '.MuiAccordionSummary-root': {
        padding: 0,
        margin: 0,
    },
});

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <BaseAccordionSummary {...props} />
))(({ theme }) => ({
    /*
    backgroundColor:
        theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    */
    flexDirection: 'row-reverse',
    /*
    '& .MuiAccordionSummary-content.Mui-expanded::before': {
        content: '"-"',
        display: 'block',
        width: '10px',
        fontSize: '20px',
        margin: '1px 5px 0 3px',
    },
    '& .MuiAccordionSummary-content::before': {
        content: '"+"',
        display: 'block',
        width: '10px',
        fontSize: '20px',
        margin: '1px 5px 0 3px',
    }, */
}));

export function InfoAccordion() {
    const theme = useTheme()
    
    return (
        <Box>

            <StyledAccordion >
                <AccordionSummary>
                    <Typography variant="info_question">
                    Quels services proposez-vous spécifiquement ?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="info_answer">
                    <p>Nous vous proposons :</p>
                    <p>Graphisme et illustration :</p>

                    <ul style={{marginTop: '1em', marginBottom: '1em', paddingLeft:'1em'}}>
                    <li style={{listStyleType: 'square'}}>Identité visuelle</li>
                    <li style={{listStyleType: 'square'}}>Brochures et imprimés</li>
                    </ul>

                    <p>Design :</p>
                    <ul style={{marginTop: '1em', marginBottom: '1em', paddingLeft: '1em'}}>
                    <li style={{listStyleType: 'square'}}>Espace</li>
                    <li style={{listStyleType: 'square'}}>Produits</li>
                    </ul>
                    
                    </Typography>
                </AccordionDetails>
            </StyledAccordion>

            <StyledAccordion >
                <AccordionSummary>
                    <Typography variant="info_question">
                        Quel est le coût de vos services ?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="info_answer">
                        Chaque mandat étant unique, les coûts de nos services varient en fonction de plusieurs facteurs, qui dépendent du temps, de la taille et de la durée. Mais dans l'ensemble, ils peuvent être résumés en deux mots : <br /><b>le juste prix</b>.
                        <br />
                        La qualité a un prix. Tout notre travail consiste à ce que votre mandat soit un investissement dont le retour soit rapide et palpable.
                    </Typography>
                </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <AccordionSummary>
                    <Typography variant="info_question">
                        Pourquoi vous appelez-vous « BAM » ?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="info_answer">
                        Dans ce monde où tout s'accélère, il est essentiel de ne pas perdre de temps, de prendre du recul et d'aller straight to the point - BAM ! - au bon endroit, là où vous voulez arriver. Ensemble, nous vous y emmenons.
                    </Typography>
                </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <AccordionSummary>
                    <Typography variant="info_question">
                        Combien êtes-de vous de collaborateurs ?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="info_answer">
                        BAM !- Bureau A.M. est une agence flexible qui fonctionne avec un réseau de proches collaborateurs freelances nationaux, graphistes et designers, réunis en fonction des mandats, autour d'Aurélie Minder
                    </Typography>
                </AccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <AccordionSummary>
                    <Typography variant="info_question">
                        Comment un mandat fonctionne-t-il ?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="info_answer">
                    D'abord on se rencontre et ensuite nous allons straight to the point : quels sont vos besoins, quelles sont les problématiques que nous pouvons résoudre ?<br /><br />

                    BAM ! est là pour vous. On se réjouit de faire votre connaissance et de voir comment nous pouvons collaborer.
                    </Typography>
                </AccordionDetails>
            </StyledAccordion>
        </Box>
    )
}