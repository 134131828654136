import React from 'react';

const BamIcon = () => {
    return (
        <svg width="53" height="14" viewBox="0 0 53 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            
            <path d="M21.5263 9.78191L19.3178 4.28895L17.1287 9.78191L17.0141 10.0489L16.443 11.4796C15.9292 12.7761 16.3857 13.5391 17.4712 13.9397V13.9777H14.5011V13.9397C15.1295 13.4437 15.6813 12.6238 16.1957 11.3461L20.6319 0.322083H20.6699L25.2581 11.0029C26.0005 12.7386 26.6289 13.5585 27.2953 13.9403V13.9783H21.2217V13.9403C22.3073 13.5397 22.7258 12.7579 22.212 11.4608L21.6409 10.0495L21.5263 9.78249V9.78191Z" fill="#596344"/>
            <path d="M35.7135 9.80415L33.505 4.3112L31.3159 9.80415L31.2013 10.0712L30.6302 11.5018C30.1164 12.7989 30.5729 13.562 31.6584 13.9625V14.0006H28.6883V13.9625C29.3167 13.4665 29.8685 12.6467 30.3823 11.3689L34.8185 0.34433H34.8565L39.4447 11.0245C40.1871 12.7603 40.8155 13.5801 41.4819 13.9619V14H35.4084V13.9619C36.4939 13.5614 36.9124 12.7796 36.3986 11.4825L35.8275 10.0712L35.7129 9.80415H35.7135Z" fill="#596344"/>
            <path d="M42.8866 9.80415L40.6781 4.3112L38.489 9.80415L38.3744 10.0712L37.8033 11.5018C37.2894 12.7989 37.746 13.562 38.8315 13.9625V14.0006H35.8614V13.9625C36.4898 13.4665 37.0416 12.6467 37.556 11.3689L41.9922 0.34433H42.0302L46.6184 11.0245C47.3608 12.7603 47.9892 13.5801 48.6556 13.9619V14H42.582V13.9619C43.6675 13.5614 44.0861 12.7796 43.5723 11.4825L43.0011 10.0712L42.8866 9.80415Z" fill="#596344"/>
            <path d="M12.0477 10.4395C12.0477 12.7023 10.4373 14 8.01194 14H0V13.9637C0.851699 13.5731 1.38832 12.7205 1.38832 11.7372V3.28114C1.38832 2.29791 0.851699 1.44469 0 1.05526V1.01837H8.17853C10.0667 1.01837 11.3626 2.18723 11.3626 3.85562C11.3626 5.2839 10.3075 6.37781 8.45561 6.85976C10.8441 7.28608 12.0477 8.71495 12.0477 10.4395ZM5.99521 6.89314C6.99539 6.89314 8.83207 6.48907 8.83207 4.41194C8.83207 2.40918 7.49343 1.29653 6.14194 1.29653H4.15912V13.74H6.90069C8.14112 13.74 9.29445 13.2575 9.29445 10.8096C9.29445 8.82562 7.95348 7.08229 5.99054 7.08229L5.99463 6.89255L5.99521 6.89314Z" fill="#596344"/>
            <path d="M50.9196 12.9436C50.9196 12.4844 51.2948 12.1085 51.7637 12.1085C52.2325 12.1085 52.5972 12.4844 52.5972 12.9436C52.5972 13.4027 52.2219 13.7892 51.7637 13.7892C51.3054 13.7892 50.9196 13.4132 50.9196 12.9436Z" fill="#596344"/>
            <path d="M50.5168 0H53L51.6994 11.3133L50.5168 0Z" fill="#596344"/>
            
            <defs>
            <clipPath id="clip0_606_2">
            <rect width="53" height="14" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default BamIcon;