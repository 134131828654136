import BamIcon from './BamIcon';
import { useNavigate } from "react-router-dom";
import { Urls } from '../urls/urls';

const MobileLogo = () => {
	const navigate = useNavigate();

	return (
		<div className='mobile-logo' onClick={() => {navigate('/')}} >
			<BamIcon />
		</div>
	);
};

export default MobileLogo;