import { StackWithImageBackground } from "../../../shared/components";
import { NavigationWithCount } from "./NavigationWithCount";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { DisplayEnum } from "../../../shared/enums/display.enum";
import MobileLogo from "../../../shared/components/MobileLogo";
import { useNavigate } from "react-router-dom"

type Props = {
    backgroundImage: string,
    elementsColor: string,
    countNumber: string,
    navigateTo: string
}

export function SlideWithNavigation(props: Props) {
    const { backgroundImage, elementsColor, countNumber, navigateTo } = props;
    const theme = useTheme(), 
          nav = useNavigate(),
          mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));

    return (
        <StackWithImageBackground background={backgroundImage}>
            <NavigationWithCount
                elementsColor={elementsColor}
                countNumber={countNumber}
                navigateTo={navigateTo} />

            { mobile && <MobileLogo /> }

            <Stack onClick={() => { nav(navigateTo); }} style={{height: '100vh', cursor: 'pointer'}} />
        </StackWithImageBackground>
    )
}