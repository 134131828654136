import { Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { DisplayEnum } from "../../../shared/enums/display.enum";
import { SelectionGrid } from "./SelectionGrid";
import arrow from '../../../assets/images/selectionPage/arrow.png'
import { CloseButton } from "../../../shared/components/CloseButton";
import { useNavigate } from "react-router-dom";
import { Urls } from "../../../shared/urls/urls";
import MobileLogo from "../../../shared/components/MobileLogo";
import { useProjects } from "../../../context/ProjectsContext";
import LoadingWrapper from "../../../components/LoadingWrapper";
import { TProject, TCategory } from "../../../shared/typing";

const StyledBox = styled(Box, { name: 'StyledBox' })({
    cursor: 'pointer'
})

const TypographyWithArrow = styled(Typography, { name: 'TypographyWithArrow' })<{ rotation: string }>(({ rotation }) => ({
    [`@media (max-width: ${DisplayEnum.mobile}px)`]: {
        position: 'relative',
        '&::before': {
            content: `url(${arrow})`,
            position: 'absolute',
            top: 0,
            left: -20,
            transform: rotation
        }
    },
}))

export function SelectionMenu() {
    const theme = useTheme()
    const navigate = useNavigate()
    const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
    const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
    const [toggleState, setToggleState] = useState('')
    const [openMenu, setOpenMenu] = useState(0)
    const [arrowRotation, setArrowRotation] = useState('180')
    const { projects, loading, error } = useProjects();

    const handleMenuToggle = (openMenu: number) => {
        if (openMenu === 1) {
            setOpenMenu(0)
            setArrowRotation('rotate(180deg)')
        } else {
            setOpenMenu(1)
            setArrowRotation('rotate(0deg)')
        }
    }


    const extractCategories = (data: TProject[]) => {
        const allCategories = data.reduce<string[]>((acc, item) => {
            return acc.concat(item.categories.map(item => item.title));
        }, []);

        const uniqueCategories = Array.from(new Set(allCategories));

        return uniqueCategories;
    };

    const categories = extractCategories(projects);

    return (
        <LoadingWrapper error={error} loading={loading}>
            <Box
                pl={mobile ? 0 : 2.1}
                mt={xlDisplay ? 5.4 : 2.7}
                mb={xlDisplay ? 3.9 : 2.7}
            >
                {mobile ? <MobileLogo /> : null}

                <CloseButton
                    background={theme.palette.background.default}
                    onClick={() => { navigate(Urls.navigationDefault) }}
                    top={60}
                />
                <Stack
                    component='ul'
                    flexDirection={mobile ? 'column' : 'row'}
                    maxWidth={xlDisplay ? 1440 : '100%'}
                    mx='auto'
                >
                    <StyledBox // Selection menu
                        component='li'
                        mr={3.5}
                        ml={mobile ? 2.3 : 2.7}
                        onClick={() => { setToggleState(''); handleMenuToggle(openMenu) }}
                    >
                        <TypographyWithArrow
                            rotation={arrowRotation}
                            variant="body_title"
                            fontSize={!xlDisplay ? 25 : 40}
                        >
                            Sélection
                        </TypographyWithArrow>
                    </StyledBox>
                    <Stack
                        visibility={(!mobile || openMenu === 1) ? 'visible' : 'hidden'}
                        flexDirection={mobile ? 'column' : 'row'}
                        mt={!xlDisplay ? 0.5 : 1}
                        ml={mobile ? 2.3 : 0}
                        rowGap={0.3}
                    >
                        {categories.map((category, index) => (
                            <StyledBox
                                component='li'
                                mr={3.5}
                                onClick={() => setToggleState(category)}
                                alignSelf='flex-start'
                                key={index}
                                borderBottom={`0.3px solid ${toggleState === category ? theme.palette.common.black : 'transparent'}`}
                            >
                                <Typography
                                    variant="body_text"
                                    fontSize={!xlDisplay ? 25 : 36}
                                >
                                    { category }
                                </Typography>
                            </StyledBox>
                        )) }

                    </Stack>
                </Stack>

                <SelectionGrid cards={projects} filter={toggleState} />
            </Box>
        </LoadingWrapper>
    )
}