export const Urls = {
    productsUrl: '/projects',
    navigationDefault: '/slide/timotee',
    navigation: '/slide',
    info: '/info',
    selection: '/selection',

    product: function(name: string) {
        return this.productsUrl + '/' + name;
    },

    nav: function(name: string) {
        return this.navigation + '/' + name;
    }
}