import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { DisplayEnum } from "../../../shared/enums/display.enum";
import { ProjectCard } from "./ProjectCard";
import { TProject } from "../../../shared/typing";
import { Urls } from "../../../shared/urls/urls";

type Props = {
    filter: string,
    cards: TProject[]
}

export function SelectionGrid(props: Props) {
    const { filter, cards } = props
    const theme = useTheme()
    const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))

    return (
        <Stack maxWidth={xlDisplay? 1440 : '100%'} display='flex' mx='auto' justifyContent='center' overflow='auto' >
            <Stack
                mt={xlDisplay ? 5 : 1}
                mx={xlDisplay ? 4.3 : 1.3}
                display='flex'
                flex='100% 1 1'
                maxWidth={1350}
                flexDirection='row'
                flexWrap='wrap'
                justifyContent='flex-start'
                gap={xlDisplay ? 5 : 1.3}
                >
                {cards.filter(card => card.categories.some(category => filter === ''|| category.title == filter))
                    .map((card, index) => (
                        <ProjectCard
                            projectPicture={card.gallery}
                            projectLink={Urls.product(card.name)}
                            label={card.label}
                            key={index}
                        />
                )) }
            </Stack>
        </Stack>
    )
}