// src/context/ProjectsContext.tsx

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { TProject } from '../shared/typing/PtoductType';

interface ProjectsContextProps {
    projects: TProject[];
    loading: boolean;
    error: string | null;
}

const ProjectsContext = createContext<ProjectsContextProps>({
    projects: [],
    loading: true,
    error: null,
});

export const useProjects = () => {
    return useContext(ProjectsContext);
};

export const ProjectsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [projects, setProjects] = useState<TProject[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        axios.get<{ data: TProject[] }>((process.env.REACT_APP_API_ENDPOINT as string) + '/projects')
            .then(response => {
                setProjects(response.data.data);
                setLoading(false);
            })
            .catch(error => {
                setError('Error fetching data');
                setLoading(false);
            });
    }, []);

    return (
        <ProjectsContext.Provider value={{ projects, loading, error }}>
            {children}
        </ProjectsContext.Provider>
    );
};
