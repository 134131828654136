
import { Stack, useTheme } from "@mui/material";
import { SelectionMenu } from "./components";
import { Fragment, useEffect, useState } from "react";
import { AnimatedPage } from "../../shared/components";


export function SelectionPage() {
    const theme = useTheme()

    return (
        <Fragment>
            <AnimatedPage withScroll>
                <Stack width='100%' minHeight='100vh' bgcolor={theme.palette.primary.main} position='relative'>
                    <SelectionMenu />
                </Stack>
            </AnimatedPage>
        </Fragment>
    )
}