import { useParams } from 'react-router-dom';
import { useProjects } from '../context/ProjectsContext';
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DisplayEnum } from '../shared/enums/display.enum';
import { CloseButton, AnimatedPage, Footer } from '../shared/components';
import { ProductsPhotoGreed } from '../shared/components/ProductPageComponent';
import LoadingWrapper from './LoadingWrapper';
import PosterLoader from './PostersLoader';

const ProjectDetails = () => {
    const { projectName } = useParams();
    const { projects, loading, error } = useProjects();
    const project = projects.find(p => p.name === projectName);
    const navigate = useNavigate()
    const theme = useTheme()
    const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
    const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

    console.log(project);

    if (!project) {
        return <div>Project not found</div>;
    }

    return (
        <LoadingWrapper loading={loading} error={error}>
            <AnimatedPage withScroll>
                <Stack bgcolor={theme.palette.background.default} >
                    <Stack maxWidth={mobile ? '100%' : 1440} mx='auto'>
                        <Box mx={xlDisplay ? 14 : 2.1} mt={xlDisplay ? 17 : 3.6} color={theme.palette.common.white}>
                            <Typography variant="product_title" mb={2}>{ project.label }. {project.title}</Typography>
                            <Typography variant="product_text" width={!mobile ? '95%' : 'none'} dangerouslySetInnerHTML={{ __html: project.about }}>
                            </Typography>
                        </Box>
                        <PosterLoader projectId={project.id} platform={ mobile ? 'mobile' : 'desktop' }>
                            {posters => (<ProductsPhotoGreed posters={posters} />)}
                        </PosterLoader>
                    </Stack>
                    <CloseButton background={theme.palette.common.white} onClick={() => { navigate(-1) }} />
                    {!mobile && (
                        <Footer isGreen />
                    )}
                </Stack>
            </AnimatedPage>
        </LoadingWrapper>
    );
}

export default ProjectDetails;