import { motion } from "framer-motion"

const motionSettings = {
    initial: {
        height: 0
    },
    animate: {
        height: '100vh'
    },
    exit: {
        y: window.innerHeight, 
        transition: { duration: 0.4 }
    }
}

type Props = {
    children: JSX.Element,
    withScroll?: boolean
}

export function AnimatedPage(props: Props) {
    const { children, withScroll } = props
    return (
        <motion.div
            initial={motionSettings.initial}
            animate={motionSettings.animate}
            exit={motionSettings.exit}
            style={withScroll? {overflow: 'auto'} : {overflow: 'hidden'}}
        >
            {children}
        </motion.div>
    )
}