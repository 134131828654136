import { useMediaQuery, useTheme } from "@mui/material";
import { SlideWithNavigation } from "./components/SlideWithNavigation";
import { Swiper, SwiperSlide } from 'swiper/react';
import { DisplayEnum } from "../../shared/enums/display.enum";
import { AnimatedPage } from "../../shared/components";
import { Urls } from "../../shared/urls/urls";
import { useNavigate } from "react-router-dom";
import LoadingWrapper from "../../components/LoadingWrapper";

import 'swiper/scss';
import 'swiper/scss/navigation';

import SwiperCore, {
    Navigation
} from 'swiper';

import { useProjects } from "../../context/ProjectsContext";

SwiperCore.use([Navigation]);

type Props = {
    initialSlide?: number
}

export function SliderPage(props: Props) {
    const { initialSlide = 0 } = props
    const theme = useTheme()
    const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile)),
        navigate = useNavigate(),
        colorSet = { green: theme.palette.success.main, gray: theme.palette.background.default, brown: theme.palette.info.main };

    const { projects, loading, error } = useProjects();

    let selectedProduct: string = '';

    return (
        <LoadingWrapper loading={loading} error={error}>
            <AnimatedPage>
                <Swiper
                    navigation={!mobile}
                    spaceBetween={0}
                    slidesPerView={1}
                    initialSlide={initialSlide}
                    loop={true}
                    onSlideChange={
                        (swiper) => {
                            selectedProduct = swiper.slides[swiper.snapIndex].getAttribute('data-product-name') as string;

                            window.history.pushState(
                                null,
                                selectedProduct,
                                Urls.nav(selectedProduct)
                            );
                        }
                    }
                    onSwiper={(swiper) => selectedProduct = swiper.slides[swiper.snapIndex].getAttribute('data-product-name') as string}
                >
                    { projects.map(project => (
                        <SwiperSlide
                            data-product-name={project.name} key={project.id}>
                            <SlideWithNavigation
                                backgroundImage={mobile ? project.poster_mobile : project.poster}
                                elementsColor={colorSet[project.color_set]}
                                countNumber={project.label}
                                navigateTo={Urls.product(project.name)}
                            />
                        </SwiperSlide>
                    )) }
                </Swiper>
            </AnimatedPage>
        </LoadingWrapper>
    )
}