import { useNavigate } from "react-router-dom";
import { DisplayEnum } from "../../shared/enums/display.enum";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { AnimatedPage, CloseButton, Footer } from "../../shared/components";
import { InfoAccordion, LogosGreed } from "./components";

import infoPhoto from '../../assets/images/infoPage/photo.png'

export function InfoPage() {
    const theme = useTheme()
    const navigate = useNavigate()
    const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
    const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
    return (
        <AnimatedPage withScroll>
            <Stack bgcolor={theme.palette.info.main}>
                <Stack maxWidth={mobile ? '100%' : 1440} mx='auto'>
                    <Box pt={xlDisplay ? 17 : 5.1} pb={xlDisplay ? 5.8 : 7.1} px={xlDisplay ? 18.6 : 2.6} maxWidth={1440} color={theme.palette.common.black}>
                        <Typography variant="info_title" mb={mobile ? 0.5 : 6}>BUREAU AM</Typography>
                        <Typography variant="info_text" mb={mobile ? 2.5 : 1}>
                            BAM ! est un bureau de design d’espace, de produit et de graphisme. 
                        <br />
                            Basé à la Chaux-de-Fonds.
                        <br />
                        <br />
                        </Typography>
                        <Typography variant="info_text" mb={2.5}>
                        Le bureau élabore des solutions sur mesure pour des entreprises et des institutions. Pour des personnes à l’esprit entrepreneurial et prêtes à oser des pas audacieux. Ensemble, nous réfléchissons à l’essentiel et matérialisons vos idées, toujours en vue de renforcer votre position – straight to the point.
                        </Typography>
                        <br />
                        <br />
                        <br />
                        <Typography variant="info_title" mb={mobile ? 3 : 5}>CONTACT</Typography>
                        <Typography variant="info_text" mb={mobile ? 3 : 5}>
                            BAM ! - Bureau A.M. <br />
                            Rue de la Serre 66 <br />
                            2300 La Chaux-de-Fonds
                        </Typography>
                        <Typography variant="info_text" mb={2}>
                            <a href="tel:+41793279335">+41 79 327 93 35</a><br />
                            <a href="mailto:info@bureau-am.ch">info@bureau-am.ch</a> 
                        </Typography>
                        <img src={infoPhoto} width={mobile ? '100%' : 616} height='auto' />
                        <Typography variant="info_title" mt={3} mb={mobile ? 1 : 4}>QUESTIONS</Typography>
                        <InfoAccordion
                            // FAQ
                        /> 
                        <Typography variant="info_title" mt={mobile ? 5 : 10} mb={mobile ? 3 : 5}>IlS NOUS FONT CONFIANCE</Typography>
                        <LogosGreed />
                    </Box>
                </Stack>
                <CloseButton background={theme.palette.background.default} onClick={() => { navigate(-1) }} />
                <Footer isGreen={false} />
            </Stack>
        </AnimatedPage>
    )
}