import React from 'react';

interface LoadingWrapperProps {
    loading: boolean;
    error: string | null;
    children: React.ReactNode;
}

const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ loading, error, children }) => {
    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return <>{children}</>;
};

export default LoadingWrapper;