import { alpha, Button, darken, styled } from "@mui/material"
import { DisplayEnum } from "../../../shared/enums/display.enum"



export const NavigationButton = styled(Button, { name: 'NavigationButton' })<{ background: string }>(({ background }) => ({
    background: `${background}`,
    borderRadius: 5,
    padding: '13px 0',
    width: '119px',
    textTransform: 'none',
    color: '#fff',
    fontFamily: 'EksellDisplayLarge',
    fontSize: 20,
    fontWeight: 300,
    ':hover': {
        background: darken(`${background}`, 0.75),
    },
    [`@media (max-width: ${DisplayEnum.mobile}px)`]: {

    },
}))
