import { useMediaQuery, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { StyledImgBox } from "../../../shared/components"
import { DisplayEnum } from "../../../shared/enums/display.enum"

type Props = {
    projectPicture: string,
    projectLink: string,
    label?: string
}

export function ProjectCard(props: Props) {
    const { projectPicture, projectLink, label } = props;
    const theme = useTheme()
    const navigate = useNavigate()
    const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
    const productKey = projectLink.replace('/navigation/', '');

    return (
        <StyledImgBox
            src={projectPicture}
            aspectratio='63.3333%'
            width={mobile? '48%' : '22%' }
            onClick={()=>{navigate(projectLink)}}
            overlayText={label}
        />
    )
}