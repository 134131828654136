import { Stack, useMediaQuery, useTheme } from "@mui/material"
import { DisplayEnum } from "../../enums/display.enum"
import { ProductPhoto } from "./ProductPhoto"
import { TPoster } from "../../typing"

type Props = {
    posters: TPoster[];
}

export function ProductsPhotoGreed(props: Props) {
    const { posters } = props;

    console.log(posters);

    const theme = useTheme()
    const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
    const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

    return (
        <Stack
        flexDirection='row'
        flexWrap='wrap'
        justifyContent='space-between'
        rowGap={mobile? 0 : 5}
        mx={xlDisplay? 14 : 0}
        mt={xlDisplay? 14 : 4}
        mb={xlDisplay? 14 : 0}
        >
            {posters.map((poster, index) => (
                <ProductPhoto poster={poster} key={index} />
            ))}
        </Stack>
    )
}