import './App.scss';
import { theme } from './theme';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { Fragment } from 'react';
import { AnimatedRoutes } from './shared/components';
import ProjectDetails from './components/ProjectDetails';
import { SelectionPage } from './pages/SelectionPage/SelectionPage';

function App() {

  return (
    <ThemeProvider theme={theme}>
      <div className="main">
        <BrowserRouter>
          <Fragment>
            <Routes>
              <Route path='selection' element={<SelectionPage />} />
              <Route path='projects/:projectName' element={<ProjectDetails />} />
              <Route path='/*' element={<AnimatedRoutes />} />
            </Routes>
          </Fragment>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
