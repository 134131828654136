import { createTheme, Theme } from '@mui/material'
import { DisplayEnum } from './shared/enums/display.enum'

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		round_close: true
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		body_title: true,
		body_text: true,
		info_title: true,
		info_text: true,
		info_question: true
		info_answer: true,
		product_title: true,
		product_text: true,
	}
}

export const theme: Theme = createTheme({
	palette: {
		primary: {
			main: '#C6CFDE',
		},
		success: {
			main: '#596344',
		},
		info: {
			main: '#90624E',
		},
		background: {
			default: '#3F3E45'
		},
		common: {
			white: '#fff',
			black: '#000'
		},
		
	},
	spacing: 10,
	components: {
		MuiTypography:{
			variants: [
				{
					props: {
						variant: 'body_title',
					},
					style: {
						fontFamily: 'EksellDisplayLarge',
						fontWeight: 300,
					},
				},
				{
					props: {
						variant: 'body_text',
					},
					style: {
						fontFamily: 'GlyphaLTProRegular',
						fontWeight: 300,
					},
				},
				{
					props: {
						variant: 'info_title',
					},
					style: {
						fontFamily: 'EksellDisplayLarge',
						fontSize: 34,
						fontWeight: 300,
						lineHeight: 1.2,
						display: 'block',
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							fontSize: 26,
						},
						color: 'white',
					},
				},
				{
					props: {
						variant: 'info_text',
					},
					style: {
						fontFamily: 'GlyphaLTProRegular',
						fontSize: 30,
						fontWeight: 300,
						lineHeight: 1.2,
						display: 'block',
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							fontSize: 20,
						},
						'a': {
							color: 'black'
						},
						'a[href^="tel"]': {
							textDecoration: 'none'
						},
					},
				},
				{
					props: {
						variant: 'product_title',
					},
					style: {
						fontFamily: 'EksellDisplayLarge',
						fontSize: 40,
						fontWeight: 300,
						lineHeight: 1.2,
						display: 'block',
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							fontSize: 26,
						},
					},
				},
				{
					props: {
						variant: 'product_text',
					},
					style: {
						fontFamily: 'GlyphaLTPro',
						fontSize: 40,
						fontWeight: 300,
						lineHeight: '48px',
						display: 'block',
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							fontSize: 20,
						},
					},
				},
				{
					props: {
						variant: 'info_question',
					},
					style: {
						fontFamily: 'GlyphaLTProRegular',
						fontSize: 30,
						fontWeight: 'bold',
						lineHeight: 1.2,
						display: 'block',
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							fontSize: 20,
						},
					},
				},
				{
					props: {
						variant: 'info_answer',
					},
					style: {
						fontFamily: 'GlyphaLTProRegular',
						fontSize: 30,
						lineHeight: 1.2,
						display: 'block',
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							fontSize: 20,
						},
					},
				}
			]
		},
		MuiButton: {
			variants: [
				{
					props: {
						variant: 'round_close',
					},
					style: {
						borderRadius: '50%',
						width: '65px',
						height: '65px',
						background: '#3F3E45',
						'&:before, :after': {
							content: '',
							width: '39px',
							height: '3px',
							background: '#fff'
						},
						'&:before': {
							transform: 'rotate(45deg)',
						},
						'&:after': {
							transform: 'rotate(-45deg)',
						},
						':hover': {
						},
						[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
							
						},
					},
				},
			],
		},
	},
})
