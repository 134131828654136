import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { CapitalLetterImageBox } from "./components";
import { StackWithImageBackground } from "../../shared/components";
import { useNavigate } from "react-router-dom";
import B from '../../assets/images/startPage/B.png';
import A from '../../assets/images/startPage/A.png';
import symbolEM from '../../assets/images/startPage/em.png';
import background from '../../assets/images/startPage/title-bg.png'
import mobileBackground from '../../assets/images/startPage/mobileStartPageBG.png'
import { Urls } from "../../shared/urls/urls";
import { DisplayEnum } from "../../shared/enums/display.enum";

export function StartPage() {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile));
    const navigate = useNavigate();

    return (
        <StackWithImageBackground
            onClick={() => { navigate(Urls.navigationDefault) }}
            background={mobile ? mobileBackground : background} position='absolute' top={0} left={0} zIndex={2}
            style={{ cursor: 'pointer' }}>
            <Stack height='100vh' alignItems='center' justifyContent='center' >
                <Stack flexDirection='row' alignItems='center' justifyContent='center' >
                    <CapitalLetterImageBox margin_right={mobile ? 1.2 : 3.1} image={B} mobile={mobile} />
                    <CapitalLetterImageBox margin_right={mobile ? 0.7 : 1.8} image={A} mobile={mobile} />
                    <CapitalLetterImageBox image={A} mobile={mobile} />
                    <CapitalLetterImageBox margin_left={mobile ? -2.8 : -6.8} image={A} mobile={mobile} />
                    <CapitalLetterImageBox width={mobile ? 12 : 31} height={mobile ? 68 : 170} image={symbolEM} mobile={mobile} />
                </Stack>
                <Box
                    mt='1em'
                    sx={ mobile ? {
                        fontFamily: 'Eksell Display Web',
                        letterSpacing: '0.2em',
                        fontSize: '23px',
                        fontWeight: 300,
                        lineHeight: '27px'
                    } : {
                        letterSpacing: '0.3em',
                        fontSize: '35pt'
                    }}
                >Bureau Aurélie Minder</Box>
            </Stack>
        </StackWithImageBackground>
    )
}