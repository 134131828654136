import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TPoster } from '../shared/typing';
import LoadingWrapper from './LoadingWrapper';

interface PosterLoaderProps {
    projectId: number;
    platform: 'desktop' | 'mobile';
    children: (posters: TPoster[]) => React.ReactNode;
}

interface TApiResult<T> {
    data: T;
}

const PosterLoader: React.FC<PosterLoaderProps> = ({ projectId, platform, children }) => {
    const [result, setResult] = useState<TPoster[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchProjectDetails = async () => {
            try {
                const response = await axios.get<TApiResult<TPoster[]>>(`${process.env.REACT_APP_API_ENDPOINT}/projects/${projectId}/posters/${platform}`);
                setResult(response.data.data);
                setLoading(false);
            } catch (err) {
                setError('Error fetching posters data');
                setLoading(false);
            }
        };

        fetchProjectDetails();
    }, [projectId, platform]);

    return (
        <LoadingWrapper loading={loading} error={error}>
            <>{result && children(result)}</>
        </LoadingWrapper>
    );
}

export default PosterLoader;