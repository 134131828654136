import { useMediaQuery, useTheme } from "@mui/material"
import { Stack } from "@mui/system"
import { useNavigate } from "react-router-dom"
import { DisplayEnum } from "../../../shared/enums/display.enum"
import { CountScoreboard } from "./CountScoreboard"
import { NavigationButton } from "./NavigationButton"
import { StyledStack } from "./StyledStack"
import { Urls } from "../../../shared/urls/urls"

type Props = {
    elementsColor: string,
    countNumber: string,
    navigateTo: string
}
export function NavigationWithCount(props: Props) {
    const { elementsColor, countNumber, navigateTo } = props
    const theme = useTheme()
    const navigate = useNavigate()
    const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

    return (
        <Stack
            flexDirection={mobile ? 'column' : 'row'}
            mt={mobile ? 2.5 : 8.5}
            mb={mobile ? 3.9 : 0}
            ml={mobile ? 2.5 : 7.7}
            mr={mobile ? 0 : 5.5}
            height={mobile ? '100vh' : 'none'}
            justifyContent={mobile ? 'stretch' : 'space-between'}
        >
            <CountScoreboard countNumber={countNumber} background={elementsColor} />
            <StyledStack>
                <NavigationButton background={elementsColor} onClick={() => { navigate(Urls.selection) }}>projets</NavigationButton>
                <NavigationButton background={elementsColor} onClick={() => { navigate(Urls.info) }} >à propos</NavigationButton>
            </StyledStack>
        </Stack>
    )
}