import { Box, styled } from "@mui/material";
import { MouseEventHandler } from "react";
import { CountScoreboard } from "../../pages/SliderPage/components/CountScoreboard"
import { theme } from "../../theme"

const BoxWithImage = styled(Box, { name: 'BoxWithImage' })<{ background: string, aspectratio: string, has_pointer?: string  }>(({ background, aspectratio, has_pointer }) => ({
    background: `url(${background}) 0 0/100% 100% no-repeat`,
    width: '100%',
    height: 'auto',
    paddingTop: `${aspectratio}`,
    cursor: `${has_pointer = 'true'? 'pointer' : 'unset'}`
}))


type Props = {
    src: string,
    width: string | number,
    aspectratio?: string,
    onClick?: MouseEventHandler<HTMLDivElement>,
    overlayText?: string
}

function overlay(text?: string) {
    if (!text) {
        return '';
    }

    return (
        <span className='overlay'>
                <CountScoreboard 
                    countNumber={text} 
                    background=''
                    textColor={theme.palette.common.black}
                />
            </span>
    );
}


export function StyledImgBox(props: Props) {
    const { src, width, aspectratio = '100%', onClick } = props;
    return (
        <Box display='flex' flex={`${width} 0 1`} onClick={onClick}
            className='image-container'
        >
            {overlay(props.overlayText)}

            <BoxWithImage 
                background={src} 
                aspectratio={aspectratio} 
                has_pointer='true'
            />
        </Box>
    )
}