import { Box, styled } from "@mui/material"

const StyledImg = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

type Props = {
    image: string,
    margin_left?: number,
    margin_right?: number,
    mobile?: boolean,
    width?: number,
    height?: number,
}

export function CapitalLetterImageBox(props: Props) {
    let { image, margin_left, margin_right, mobile, width, height } = props;
    width = width ??= mobile ? 64 : 160;
    height = height ??= mobile ? 68 : 171;

    return (
        <Box
            display='inline-block'
            position='relative'
            ml={margin_left}
            mr={margin_right}
            width={width}
            height={height}>
            <StyledImg src={image} alt="capital letter" />
        </Box>
    )
}